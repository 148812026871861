<template>
  <b-row class="OrganizationDetails p-2">
    <div class="overlay" v-if="loader">
      <b-spinner class="loader" variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-col md="12" class="mb-2">
      <div>
        <b-form-select
          v-model="selected"
          :options="DataOptions"
          @change="callFunc()"
        ></b-form-select>
      </div>
    </b-col>
    <b-col md="3" class="pl-0">
      <div class="card">
        <h2>Reports</h2>

        <div class="form-group search">
          <div class="input-group">
            <b-form-input
              id="search"
              v-model="search"
              type="text"
              placeholder="Search"
            ></b-form-input>
          </div>
        </div>
        <div class="workflowList">
          <ul
            v-for="(request, index) in filteredList"
            :key="request.request_name + '-' + index"
            @click="getAccessRightsData()"
          >
            <li>
              <router-link
                :style="{
                  color: request.request_type_vset == 'REPORT' ? '' : '#28a745'
                }"
                :to="`dashboard/request/submittedRequest/${request.request_id}`"
                >{{ request.request_name }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </b-col>
    <b-col md="9" class="pr-0">
      <div
        class="card"
        v-if="selected === 'WORKLISTS' && !forMeFlag && !globalFlag"
      >
        <span>
          <h2>
            Worklists
            <div style="float: right">
              <h6>
                Default Worklist can be set through notification bell icon
              </h6>
            </div>
          </h2>
        </span>
        <!-- <h2>
          Worklists
        </h2> -->
        <b-row>
          <b-col md="4">
            <basic-select
              :selectedOption="selectedEvent"
              :title="selectedEvent.text"
              :options="modifyEventsList"
              @select="selectedEventFun($event)"
            >
            </basic-select>
          </b-col>
          <b-col md="8">
            <div class="form-group search">
              <div class="input-group">
                <b-input-group prepend="Search" class="mb-2 mr-sm-1 mb-sm-0">
                  <b-form-input
                    id="searchEvent"
                    type="text"
                    v-model="searchEventName"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-col md="12" class="text-centre pl-3">
          <div class="">
            <b-table
              striped
              hover
              outlined
              :items="notificationDetailsList"
              :fields="notificationFields"
              sticky-header
              show-empty
              class="bg-white"
              size="lg"
              responsive
              @row-clicked="rowSelected"
            >
            </b-table>
          </div>
        </b-col>
      </div>
      <div class="card" v-if="selected === 'SALES_AGREEMENT'">
        <span>
          <h2>User Dashboard</h2>
        </span>
        <b-row style="margin-top: -0.3rem" v-if="globalFlag">
          <b-col md="6" style="margin-top: 3rem">
            <b-table
              hover
              striped
              outlined
              bordered
              :items="dashboardGlobalData"
              :fields="dashboardGlobalFields"
              show-empty
              class="bg-white"
              sticky-header
            >
            </b-table>
          </b-col>
          <b-col md="6">
            <div class="chartConts">
              <div id="chart-cont" style="border-radius: 18px"></div>
            </div>
          </b-col>
        </b-row>
        <b-row
          style="margin-top: -22rem; margin-bottom: 0.5rem; padding-left: 1rem"
          v-if="globalFlag"
        >
          <b-col md="2">
            <a href="#" @click="fetchForMeData()">For Me</a>
          </b-col>
          <b-col md="2">
            <a href="#" @click="fetchBillingData(true)">Billing Status</a>
          </b-col>
          <b-col md="2" v-if="salesAgreementGlobalAccessFlag">
            <a href="#" @click="fetchGlobalData()">Global</a>
          </b-col>
        </b-row>

        <b-row style="margin-top: -0.3rem" v-if="forMeFlag">
          <b-col md="6" style="margin-top: 3rem">
            <b-table
              hover
              striped
              outlined
              bordered
              :items="dashboardForMeData"
              :fields="dashboardForMeFields"
              show-empty
              class="bg-white"
              sticky-header
            >
            </b-table>
          </b-col>
          <b-col md="6">
            <div class="chartConts">
              <div id="chart-cont" style="border-radius: 18px"></div>
            </div>
          </b-col>
        </b-row>
        <b-row
          style="margin-top: -22rem; margin-bottom: 0.5rem; padding-left: 1rem"
          v-if="forMeFlag"
        >
          <b-col md="2">
            <a href="#" @click="fetchForMeData()">For Me</a>
          </b-col>
          <b-col md="2">
            <a href="#" @click="fetchBillingData(true)">Billing Status</a>
          </b-col>
          <b-col md="2" v-if="salesAgreementGlobalAccessFlag">
            <a href="#" @click="fetchGlobalData()">Global</a>
          </b-col>
        </b-row>

        <b-row style="margin-top: -0.3rem" v-if="billingFlag">
          <b-col md="6" style="margin-top: 6rem">
            <b-table
              hover
              striped
              outlined
              bordered
              :items="dashboardBillingData"
              :fields="dashboardBillingFields"
              show-empty
              class="bg-white"
              sticky-header
            >
            </b-table>
          </b-col>
          <b-col md="6">
            <div class="chartConts">
              <div id="chart-cont" style="border-radius: 18px"></div>
            </div>
          </b-col>
        </b-row>
        <b-row
          style="margin-top: -22rem; margin-bottom: 0.5rem; padding-left: 1rem"
          v-if="billingFlag"
        >
          <b-col md="2">
            <a href="#" @click="fetchForMeData()">For Me</a>
          </b-col>
          <b-col md="2">
            <a href="#" @click="fetchBillingData(true)">Billing Status</a>
          </b-col>
          <b-col md="2" v-if="salesAgreementGlobalAccessFlag">
            <a href="#" @click="fetchGlobalData()">Global</a>
          </b-col>
        </b-row>
        <b-row v-if="billingFlag" style="margin-top: -1rem">
          <b-col md="2">
            <label for="filter">Billing Period</label>
            <date-picker
              v-model="monthYear"
              format="MMM-YYYY"
              type="month"
              @change="fetchBillingData()"
            ></date-picker>
          </b-col>
        </b-row>
      </div>
      <div class="card" v-if="selected === 'INVENTORY_SUMMARY'">
        <span>
          <h2>Inventory Dashboard</h2>
        </span>
        <b-row>
          <b-col md="2" style="margin-top: -5px">
            <b-form-input
              v-model="selectedProject.text"
              :title="selectedProject.text"
              @click="openValueSetModal('FMS_PROJECT')"
              placeholder="Project"
              readonly
            ></b-form-input>
            <span
              aria-hidden="true"
              class="clearable-icon-grid"
              v-if="selectedProject.text"
              @click="clearVsetValues('FMS_PROJECT')"
              >×</span
            >
          </b-col>
          <b-col md="2" style="margin-top: -5px">
            <b-form-input
              v-model="selectedTower.text"
              :title="selectedTower.text"
              placeholder="Tower"
              @click="openValueSetModal('FMS_TOWER_VSET')"
              readonly
            ></b-form-input>
            <span
              aria-hidden="true"
              class="clearable-icon-grid"
              v-if="selectedTower.text"
              @click="clearVsetValues('FMS_TOWER_VSET')"
              >×</span
            >
          </b-col>
          <b-col
            md="0"
            style="margin-top: -4px"
            class="text-center pl-4"
            v-if="changeFlag"
          >
            <b-form-group class="mb-0">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            md="0"
            style="margin-top: -4px"
            class="text-center pl-3"
            v-if="changeFlag"
          >
            <div class=" p-0" v-if="totalRows <= perPage">
              {{ totalRows }} / {{ totalRows }}
            </div>
            <div class="p-0" v-else>{{ perPage }} / {{ totalRows }}</div>
          </b-col>
          <b-col md="1" style="margin-top: -5px;">
            <b-button
              style="background-color:#00b6c4; border:0px; border-radius:5px; cursor:pointer;"
              @click="changeStatus()"
            >
              {{ figure }}
            </b-button>
          </b-col>
          <b-col v-if="!changeFlag">
            <h6 style="text-align: right; color:red">
              * Display area measurements in square feet (Sqft)
            </h6>
          </b-col>

          <b-col md="2" style="margin-top: -5px;" v-if="changeFlag">
            <b-button
              style="background-color:#00b6c4; border:0px; border-radius:5px; cursor:pointer;"
              @click="downloadExcl()"
            >
              DOWNLOAD EXCEL
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" v-if="!changeFlag">
            <div class="chartConts">
              <div id="chart-cont" style="border-radius: 18px"></div>
            </div>
          </b-col>
          <b-col md="6" v-if="!changeFlag">
            <div class="chartConts">
              <div id="chart-cont1" style="border-radius: 18px"></div>
            </div>
          </b-col>
          <b-col md="12" v-else>
            <b-table
              hover
              striped
              outlined
              bordered
              :items="inventoryData"
              :fields="inventoryFields"
              show-empty
              class="bg-white"
              sticky-header
            >
              <template #head(tot_floor_area)="">
                <div class="alginLeft">Total Floor Area</div>
              </template>
              <template #head(unsold_area)="">
                <div class="alginLeft">Unsold Area</div>
              </template>
              <template #head(sold_area)="">
                <div class="alginLeft">Sold Area</div>
              </template>
              <template #head(sold_out_not_Leased_by_us)="">
                <div class="alginLeft">Sold Outright Not Leased By Us</div>
              </template>
              <template #head(sold_out_Leased_by_us)="">
                <div class="alginLeft">Sold Outright Leased By Us</div>
              </template>
              <template #head(sold_nonout)="">
                <div class="alginLeft">Sold Non Outright</div>
              </template>
              <template #head(total_leaseable_area)="">
                <div class="alginLeft">Total Leaseable Area</div>
              </template>
              <template #head(leased_area)="">
                <div class="alginLeft">Leased Area</div>
              </template>
              <template #head(vacant_area)="">
                <div class="alginLeft">Vacant Area</div>
              </template>
            </b-table>
            <div>
              <b-row>
                <b-col md="3" class="p-0">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <b-row
        style="display: flex; justify-content: center; align-items: center"
      >
        <b-col md="0">
          <b-form-radio
           v-if="dashboardName"
            v-model="oldDashboard"
            name="some-radios"
            :value="true"
            @change="oldDash()"
          ></b-form-radio>
        </b-col>
        <b-col md="0">
          <b-form-radio
           v-if="dashboardName"
            v-model="newDashboard"
            name="some-radios"
          :value="true"
            @change="oldDashboardFunction()"
          ></b-form-radio>
          </b-col>  
          <b-col md="0">                                    
            <b-form-radio
             v-if="dashboardName"
              v-model="inventoryDashboard"
              name="some-radios"
              :value="true"
              @change="inventoryDashboardFunction()"
            ></b-form-radio>
          </b-col>
      </b-row> -->
    </b-col>
    <b-modal
      v-model="showAddIntWorkflow"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="add-workflow-modal"
      title="Add Internal workflow"
      @hidden="hideIntWorkflow(false)"
      ><template #modal-title>
        <div class="dropdown">
          <button class="dropbtn">
            <b-icon icon="list" aria-hidden="true"></b-icon>
          </button>
          <div class="dropdown-content">
            <ul class="history-menu">
              <li class="margin-li">
                <button
                  class="btn btn-vatika"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="showHideHistory(true, 'historyRecord')"
                >
                  History
                </button>
              </li>
              <li class="margin-li">
                <button
                  class="btn btn-vatika"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="showHideHistory(true, 'approvalHistory')"
                >
                  Approval History
                </button>
              </li>
              <li class="margin-li" v-if="wfHdrIdkeyName === 'party_req_id'">
                <button
                  class="btn btn-vatika"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="showHideTermsConditionModal(true)"
                >
                  View TnC
                </button>
              </li>
              <li
                class="margin-li"
                v-if="
                  wfHdrIdkeyName !== 'party_req_id' &&
                    wfHdrIdkeyName !== 'vendor_inv_id' &&
                    wfHdrIdkeyName !== 'liq_hdr_id' &&
                    wfHdrIdkeyName !== 'remedy_batch_id'
                "
              >
                <button
                  class="btn btn-vatika"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="showHideHistory(true, 'sendForApproval')"
                >
                  Send For Approval
                </button>
              </li>
              <li
                class="margin-li"
                v-if="
                  wfHdrIdkeyName !== 'party_req_id' &&
                    wfHdrIdkeyName !== 'vendor_inv_id' &&
                    wfHdrIdkeyName !== 'liq_hdr_id' &&
                    wfHdrIdkeyName !== 'remedy_batch_id' &&
                    wfHdrIdkeyName !== 'requisition_header_id'
                "
              >
                <button
                  class="btn btn-vatika"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="checkOpenKmPasswordSaved()"
                >
                  Upload Doc
                </button>
              </li>
            </ul>
          </div>
        </div>
        {{
          wfHdrIdkeyName === 'lease_master_agreement_hdr_id'
            ? 'Lease Master Agreement'
            : wfHdrIdkeyName === 'lease_tenant_agreement_hdr_id'
            ? 'Lease Tenant Agreement'
            : wfHdrIdkeyName === 'lease_investor_agreement_hdr_id'
            ? 'Investor Lease Agreement'
            : wfHdrIdkeyName === 'lease_tenant_schedule_hdr_id'
            ? 'Tenant Lease Schedule'
            : wfHdrIdkeyName === 'lease_investor_schedule_hdr_id'
            ? 'Lease Investor Schedule'
            : wfHdrIdkeyName === 'ap_h2h_payment_batch_id'
            ? 'H2H Bank Transfer'
            : wfHdrIdkeyName === 's_nfa_hdr_id'
            ? 'Sourcing Approval'
            : wfHdrIdkeyName === 'lease_prj_unit_id'
            ? 'Lease Unit'
            : wfHdrIdkeyName === 'party_req_id'
            ? 'Party Onboarding'
            : wfHdrIdkeyName === 'sales_agreement_hdr_id'
            ? 'Sales Agreement'
            : wfHdrIdkeyName === 'vendor_inv_id'
            ? 'Invoice Details'
            : wfHdrIdkeyName === 'requisition_header_id'
            ? 'Purchase Requisition Entry Form'
            : wfHdrIdkeyName === 'prov_hdr_id'
            ? 'Add Provision'
            : wfHdrIdkeyName === 'cf_hdr_id'
            ? 'Carry Forward Details'
            : 'Internal Workflow'
        }}
        <!-- <accessRightButton
          v-if="
            wfHdrIdkeyName === 'vendor_inv_id' &&
              invoiceApprovalDetails.approval_status !== 'APPROVED' &&
              invoiceApprovalDetails.inv_level !== 'BUYER'
          "
          formName="vendorInvoice"
          :accessButtons="accessButtons"
          :roleMappingId="285"
        /> -->
      </template>
      <addEditMasterAgreement
        v-if="wfHdrIdkeyName === 'lease_master_agreement_hdr_id'"
        :leaseMasterAgreementHdrDetails="leaseHdrDetails"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <addEditTenantAgreement
        v-else-if="wfHdrIdkeyName === 'lease_tenant_agreement_hdr_id'"
        :selectedLeaseDetails="leaseHdrDetails"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <addEditInvestorLease
        v-else-if="wfHdrIdkeyName === 'lease_investor_agreement_hdr_id'"
        :selectedLeaseDetails="leaseHdrDetails"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <leaseTenantSchedule
        v-else-if="wfHdrIdkeyName === 'lease_tenant_schedule_hdr_id'"
        :scheduleHdrId="leaseTenantScheduleHdrId"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <leaseInvestorSchedule
        v-else-if="wfHdrIdkeyName === 'lease_investor_schedule_hdr_id'"
        :scheduleHdrId="leaseInvestorScheduleHdrId"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <bankBatch
        v-else-if="wfHdrIdkeyName === 'ap_h2h_payment_batch_id'"
        :bankBatchId="bankBatchId"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <addEditSourcingApproval
        v-else-if="wfHdrIdkeyName === 's_nfa_hdr_id'"
        :eventId="eventId"
        :nfaHdrId="resHdrId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <addEditMutation
        v-else-if="wfHdrIdkeyName === 'fms_mutation_hdr_id'"
        :eventId="eventId"
        :selectedMutationHdrId="fmsMutationHdrId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
      <leaseUnit
        v-else-if="wfHdrIdkeyName === 'lease_prj_unit_id'"
        :eventId="eventId"
        :leasePrjUnitId="leasePrjUnitId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
        :unit_disp_name="unitDisplayName"
        :unit_name="unitName"
        :project_name="projectName"
        :floor_name="floorName"
        :approval_status="approvalStatus"
        :ver="version"
      />
      <addEditPartyOnboarding
        v-else-if="wfHdrIdkeyName === 'party_req_id'"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
        :dashboardDetails="dashboardDetails"
      />
      <salesAgreement
        v-else-if="wfHdrIdkeyName === 'sales_agreement_hdr_id'"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
        :wfSalesAgreementDetails="wfSalesAgreementDetails"
      />
      <invoiceApprovalDetails
        v-else-if="wfHdrIdkeyName === 'vendor_inv_id'"
        :eventId="eventId"
        :invoiceApprovalDetails="invoiceApprovalDetails"
        @updateInvoice="updateInvoiceDetails()"
      />

      <addEditAssetLiquidation
        v-else-if="wfHdrIdkeyName === 'liq_hdr_id'"
        :eventId="eventId"
        :assetLiquidationDetails="assetLiquidationDetails"
        @updateAssetLiq="updateAssetLiqDetails()"
      />

      <remedyBatchDetails
        v-else-if="wfHdrIdkeyName === 'remedy_batch_id'"
        :eventId="eventId"
        :assetRemedyDetails="assetRemedyDetails"
        @updateRemedyDetails="updateRemedyDetails()"
      />
      <purReqEntryForm
        v-else-if="wfHdrIdkeyName === 'requisition_header_id'"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
        :wfPurReqDetails="wfPurReqDetails"
      />
      <addProvisionFile
        v-else-if="wfHdrIdkeyName === 'prov_hdr_id'"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
        :wfProvisionDetails="wfProvisionDetails"
      />
      <addEditCarryForward
        v-else-if="wfHdrIdkeyName === 'cf_hdr_id'"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
        :wfCarryForwardDetails="wfCarryForwardDetails"
      />
      <addRequest
        v-else-if="wfHdrIdkeyName === 'rfp_header_id'"
        :eventId="eventId"
        @getAllRfp="getAllRfp"
        :headerAllData="rfpDataFromDashboard.headerAllData"
        :locationAllData="rfpDataFromDashboard.locationAllData"
        :itemAllData="rfpDataFromDashboard.itemAllData"
        :vendorAllData="rfpDataFromDashboard.vendorAllData"
        :negotiationAllData="rfpDataFromDashboard.negotiationAllData"
        :rfphdrid="rfpDataFromDashboard.rfpHeaderId"
        :userId="rfpDataFromDashboard.userId"
        :boqAllData="rfpDataFromDashboard.boqAllData"
        :tncData="rfpDataFromDashboard.tncData"
        :extendData="rfpDataFromDashboard.extendData"
        :menuFromDashboard="rfpDataFromDashboard.menuIdForRfp"
        :wfDtlId="rfpDataFromDashboard.wfDtlId"
      />
      <addInternalWorkflow
        v-else
        :propsSelectedGridData="selectedGridData"
        :eventId="eventId"
        :wf_inprocess_dtl_id="wf_inprocess_dtl_id"
      />
    </b-modal>
    <b-modal
      v-model="showHistory"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :size="
        historyType === 'approvalHistory' || historyType === 'sendForApproval'
          ? 'add-workflow-modal'
          : 'lg'
      "
      @hidden="showHideHistory(false, null)"
    >
      <template #modal-title>
        {{
          historyType === 'historyRecord'
            ? 'History'
            : historyType === 'approvalHistory'
            ? 'Approval History'
            : historyType === 'sendForApproval'
            ? 'Send For Approval'
            : 'Upload Document'
        }}
        <accessRightButton />
      </template>
      <history-record
        v-if="historyType === 'historyRecord'"
        :wfHeaderId="wfHeaderId"
        :eventId="eventId"
      />
      <ApprovalHistory
        v-if="historyType === 'approvalHistory'"
        :wfHeaderId="wfHeaderId"
        :eventId="eventId"
        formName="vendorInvoice"
      />
      <sendForApproval
        v-if="historyType === 'sendForApproval'"
        :wfHeaderId="wfHeaderId"
        :eventId="eventId"
      />
      <UploadDocument
        v-if="historyType === 'uploadDocument'"
        :wfHdrId="wfHeaderId"
        :selectedGridData="leaseHdrDetails"
        :openkmAuthToken="openkmAuthToken"
      />
    </b-modal>
    <b-modal
      v-model="showTermsConditionModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      size="lg"
      @hidden="showHideTermsConditionModal(false)"
    >
      <template #modal-title>
        Terms Condition
        <accessRightButton
          :accessButtons="accessButtonsDownload"
          formName="vendorInvoice"
          :roleMappingId="285"
        ></accessRightButton>
      </template>
      <termsCondition
        :dashboardDetails="dashboardDetails"
        @closeModal="showHideTermsConditionModal(false)"
        formName="dashboard"
      />
    </b-modal>
    <valueSetModal
      v-if="showValueSetModal"
      @closeValueSetModal="closeValueSetModal"
      @selectedvalueSet="selectedvalueSet"
      :parent_value_set_id="parent_value_set_id"
      :isDependent="isDependent"
    ></valueSetModal>
  </b-row>
</template>

<script>
import commonHelper from '@/app/utility/common.helper.utility';
import addInternalWorkflow from '../onlineApproval/internalWorkFlow/addInternalWorkflow';
import HistoryRecord from '../onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '../onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '../onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '../onlineApproval/internalWorkFlow/history/sendForApproval';
import { BasicSelect } from 'vue-search-select';
import addEditMasterAgreement from '../leaseAdmin/lease/leases/masterAgreement/addEditMasterAgreement';
import addEditTenantAgreement from '../leaseAdmin/lease/leases/tenantLease/addEditTenantLease';
import addEditInvestorLease from '../leaseAdmin/lease/leases/investorLease/addEditInvestorLease';
import leaseTenantSchedule from '../leaseAdmin/lease/schedules/tenantSchedule/tenantDetails';
import leaseInvestorSchedule from '../leaseAdmin/lease/schedules/investorSchedule/investorDetails';
import bankBatch from '../payable/payments/hostToHostBankTransfer/payments/paymentDetails';
import addEditSourcingApproval from '../onlineApproval/nfaApproval/addNfaApproval';
import addEditMutation from '../fms/mutation/addEditMutation';
// import assignSubUnitDetails from '../leaseAdmin/lease/masterLease/leaseAssignSubUnit/assignSubUnitDetails';
import leaseUnit from '../leaseAdmin/lease/masterLease/leaseUnit/leaseUnitDetails';
import addEditPartyOnboarding from '../generalLedger/partyOnboarding/partyDetails';
import salesAgreement from '../fms/salesAgreement/createSalesAgreement';
import invoiceApprovalDetails from '../generalLedger/invoiceApproval/invoiceApprovalDetails';
import termsCondition from '../generalLedger/partyOnboarding/partyDetails/termsCondition';
import DatePicker from 'vue2-datepicker';
import Highcharts from 'highcharts';
import addEditAssetLiquidation from '../assetAdmin/assetLiquidation/addEditAssetLiquidation';
import remedyBatchDetails from '../assetAdmin/assetAudit/auditDetails/remedyBatchDetails'
import purReqEntryForm from '../onlineApproval/purchaseRequisition/purReqEntryForm';
import addProvisionFile from '../provisionsAdmin/uploadProvisions/addProvisionFile';
import addEditCarryForward from '../provisionsAdmin/carryForward/addEditCarryForward';
import appStrings from '@/app/utility/string.utility';
import addRequest from "../onlineApproval/rfp/addRequest";

export default {
  name: 'dashboard',
  components: {
    DatePicker,
    addInternalWorkflow,
    addEditSourcingApproval,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    BasicSelect,
    addEditMasterAgreement,
    addEditTenantAgreement,
    addEditInvestorLease,
    leaseTenantSchedule,
    leaseInvestorSchedule,
    bankBatch,
    addEditMutation,
    // assignSubUnitDetails,
    addEditPartyOnboarding,
    leaseUnit,
    salesAgreement,
    invoiceApprovalDetails,
    termsCondition,
    addEditAssetLiquidation,
    remedyBatchDetails,
    purReqEntryForm,
    addProvisionFile,
    addEditCarryForward,
    addRequest
  },
  watch: {
    searchEventName: function() {
      if (this.searchEventName && this.searchEventName.length > 0) {
        this.notificationDetailsList = this.filteredEventList();
      } else {
        this.notificationDetailsList = this.currentNotificationDetailsList;
      }
    },
    currentPage: function() {
      this.getInventoryDashboardData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInventoryDashboardData();
    }
  },
  props: ['responsibilities', 'activeMenu', 'reports'],
  data() {
    return {
      openkmAuthToken: null,
      leaseHdrDetails: null,
      wfHdrIdkeyName: null,
      eventsList: [],
      selectedEvent: {
        text: null,
        value: null
      },
      modifyEventsList: [],
      showHistory: false,
      historyType: null,
      showAddIntWorkflow: false,
      search: '',
      searchEventName: null,
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      wf_inprocess_dtl_id: null,
      wfHeaderId: null,
      selectedGridData: null,
      eventId: null,
      notificationAllList: [],
      currentNotificationDetailsList: [],
      notificationDetailsList: [],
      notificationFields: [
        {
          key: 'wf_event_name',
          label: 'Event Name'
        },
        { key: 'nfa_number', label: 'Number' },
        {
          key: 'nfa_type_vset_code',
          label: 'Type'
        },
        {
          key: 'nfa_cat_vset_code',
          label: 'Cat.'
        },
        {
          key: 'nfa_sub_cat_vset_code',
          label: 'Sub Cat.'
        },
        {
          key: 'transactional_currency_amount_formated',
          label: 'Amount'
        },
        {
          key: 'approval_status',
          label: 'Status'
        }
      ],
      leaseTenantScheduleHdrId: null,
      leaseInvestorScheduleHdrId: null,
      bankBatchId: null,
      resHdrId: null,
      leasePrjUnitId: null,
      fmsMutationHdrId: null,
      unitDisplayName: null,
      unitName: null,
      projectName: null,
      approvalStatus: null,
      version: null,
      dashboardDetails: {},
      wfSalesAgreementDetails: null,
      wfProvisionDetails: null,
      wfCarryForwardDetails: null,
      invoiceApprovalDetails: {},
      showTermsConditionModal: false,
      accessButtonsDownload: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      },
      assetLiquidationDetails: {},
      oldDashboard: true,
      newDashboard: false,
      dashboardGlobalData: [
        {
          names_col: 'Total Active Sales Agreement',
          num: 0
        },
        {
          names_col: 'Expire in Next 7 Days',
          num: 0
        },
        {
          names_col: 'Expire in Next 15 Days',
          num: 0
        },
        {
          names_col: 'Expire in Next 30 Days',
          num: 0
        },
        {
          names_col: 'Expire in More Than 30 Days',
          num: 0
        }
      ],
      dashboardGlobalFields: [
        {
          key: 'names_col',
          label: 'Global Data'
        },
        {
          key: 'num',
          label: 'Value'
        }
      ],
      chartData: [],
      dashboardForMeData: [
        {
          names_col: 'Total Active Sales Agreement',
          num: 0
        },
        {
          names_col: 'Expire in Next 7 Days',
          num: 0
        },
        {
          names_col: 'Expire in Next 15 Days',
          num: 0
        },
        {
          names_col: 'Expire in Next 30 Days',
          num: 0
        },
        {
          names_col: 'Expire in More Than 30 Days',
          num: 0
        }
      ],
      dashboardForMeFields: [
        {
          key: 'names_col',
          label: 'For Me'
        },
        {
          key: 'num',
          label: 'Value'
        }
      ],
      dashboardBillingData: [
        {
          names_col: 'Total Active Sales Agreement',
          num: 0
        },
        {
          names_col: 'Time Sheet Unprocess',
          num: 0
        },
        {
          names_col: 'Time Sheet Process',
          num: 0
        },
        {
          names_col: 'Invoice Generated',
          num: 0
        },
        {
          names_col: 'Billing Period',
          num: null
        }
      ],
      dashboardBillingFields: [
        {
          key: 'names_col',
          label: 'Billing Status'
        },
        {
          key: 'num',
          label: 'Value'
        }
      ],
      globalFlag: false,
      forMeFlag: false,
      billingFlag: false,
      monthYear: null,
      wfPurReqDetails: null,
      isGloablAccess: null,
      dashboardName: null,
      dashboardData: [],
      chartData1: [],
      selectedProject: {
        text: null,
        value: null
      },
      selectedTower: {
        text: null,
        value: null
      },
      figure: 'TABLE',
      changeFlag: false,
      inventoryData: [],
      inventoryFields: [
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'fms_tower_name',
          label: 'Tower'
        },
        {
          key: 'floor_name',
          label: 'Floor'
        },
        {
          key: 'tot_floor_area',
          label: 'Total Floor Area',
          class: 'alignClass'
        },
        {
          key: 'unsold_area',
          class: 'alignClass'
        },
        {
          key: 'sold_area',
          class: 'alignClass'
        },
        {
          key: 'sold_out_not_Leased_by_us',
          label: 'Sold Outright Not Leased By Us',
          class: 'alignClass'
        },
        {
          key: 'sold_out_Leased_by_us',
          label: 'Sold Outright Leased By Us',
          class: 'alignClass'
        },
        {
          key: 'sold_nonout',
          label: 'Sold Non Outright',
          class: 'alignClass'
        },
        {
          key: 'total_leaseable_area',
          class: 'alignClass'
        },
        {
          key: 'leased_area',
          class: 'alignClass'
        },
        {
          key: 'vacant_area',
          class: 'alignClass'
        }
      ],
      showValueSetModal: false,
      isDependent: false,
      graphData: null,
      dashboardStatus1: 'Leasing Status',
      filterData: null,
      inventoryDashboard: false,
      DataOptions: [{ value: 'WORKLISTS', text: 'WORKLISTS' }],
      selected: 'WORKLISTS',
      salesAgreementGlobalAccessFlag: false,
      inventoryGlobalAccessFlag: false,
      leasedArea: [],
      vacantArea: [],
      soldNonOutright: [],
      soldOutrightLeased: [],
      soldOutrightNotLeased: [],
      unsoldArea: [],
      rfpDataFromDashboard: {}
    };
  },
  created() {
    this.responsibilities;
    this.activeMenu;
    this.reports;
    this.$emit('loaded');
    this.getEventsList();
    this.getAdmindDashboardData();
  },
  computed: {
    filteredList() {
      return this.reports.filter(report => {
        return report.request_name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    fetchGlobalData() {
      this.globalFlag = true;
      this.forMeFlag = false;
      this.billingFlag = false;
      this.getdashboardGlobalData();
    },
    fetchForMeData() {
      this.forMeFlag = true;
      this.globalFlag = false;
      this.billingFlag = false;
      this.getForMeDashboardData();
    },
    fetchBillingData(type) {
      if (type) {
        this.monthYear = null;
      }
      this.forMeFlag = false;
      this.globalFlag = false;
      this.billingFlag = true;
      this.getBillingDashboardData();
    },
    oldDash() {
      this.oldDashboard = false;
      this.newDashboard = false;
      this.inventoryDashboard = false;
      this.forMeFlag = false;
      this.globalFlag = false;
    },
    oldDashboardFunction() {
      this.oldDashboard = false;
      this.inventoryDashboard = false;
      this.forMeFlag = true;
      this.globalFlag = false;
      this.billingFlag = false;
      // this.getForMeDashboardData();
      this.fetchForMeData();
    },
    inventoryDashboardFunction() {
      this.inventoryDashboard = true;
      this.oldDashboard = false;
      this.newDashboard = false;
      this.getLeaseInventoryDataForChart();
    },
    getdashboardGlobalData() {
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/getdashboardGlobalData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            let result = response.data.data;
            this.dashboardGlobalData[0].num = result.total_sa_global;
            this.dashboardGlobalData[1].num = result.expire_in_7_days_global;
            this.dashboardGlobalData[2].num = result.expire_in_15_days_global;
            this.dashboardGlobalData[3].num = result.expire_in_30_days_global;
            this.dashboardGlobalData[4].num =
              result.expire_in_more_than_30_days_global;
            this.totalLines('global');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getForMeDashboardData() {
      const payload = {
        user_id: this.$store.state.auth.userId
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/getForMeDashboardData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            let result = response.data.data;
            this.dashboardForMeData[0].num = result.total_sa;
            this.dashboardForMeData[1].num = result.expire_in_7_days;
            this.dashboardForMeData[2].num = result.expire_in_15_days;
            this.dashboardForMeData[3].num = result.expire_in_30_days;
            this.dashboardForMeData[4].num = result.expire_in_more_than_30_days;
            this.totalLines('for_me');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBillingDashboardData() {
      const payload = {
        user_id: this.$store.state.auth.userId,
        month_year: this.monthYear
          ? commonHelper.formattedDate(this.monthYear).slice(3)
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/getBillingDashboardData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            let result = response.data.data;
            if (result.length) {
              this.dashboardBillingData[0].num = result[0].total_sa;
              this.dashboardBillingData[1].num = result[0].unprocessed_count;
              this.dashboardBillingData[2].num = result[0].processed_count;
              this.dashboardBillingData[3].num = result[0].numbers_invoiced;
              this.dashboardBillingData[4].num = result[0].month_year;
            } else {
              this.dashboardBillingData = [
                {
                  names_col: 'Total Active Sales Agreement',
                  num: 0
                },
                {
                  names_col: 'Time Sheet Unprocess',
                  num: 0
                },
                {
                  names_col: 'Time Sheet Process',
                  num: 0
                },
                {
                  names_col: 'Invoice Generated',
                  num: 0
                },
                {
                  names_col: 'Billing Period',
                  num: null
                }
              ];
            }
            this.totalLines('billing_status');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    totalLines(type, size) {
      if (type == 'global') {
        // let totalSaGlobal = this.dashboardGlobalData[0].num;
        let expireInSevenDaysGlobal = this.dashboardGlobalData[1].num;
        let expireInFifteenDaysGlobal = this.dashboardGlobalData[2].num;
        let expireInThirtyDaysGlobal = this.dashboardGlobalData[3].num;
        let expireInMoreThanThirtyDaysGlobal = this.dashboardGlobalData[4].num;
        this.chartData = [
          // ['Total Sales Agreement', totalSaGlobal],
          ['Expire In 7 Days', expireInSevenDaysGlobal],
          ['Expire In 15 Days', expireInFifteenDaysGlobal],
          ['Expire In More Than 30 Days', expireInMoreThanThirtyDaysGlobal],
          ['Expire In 30 Days', expireInThirtyDaysGlobal]
        ];
      } else if (type == 'for_me') {
        // let totalSa = this.dashboardForMeData[0].num;
        let expireInSevenDays = this.dashboardForMeData[1].num;
        let expireInFifteenDays = this.dashboardForMeData[2].num;
        let expireInThirtyDays = this.dashboardForMeData[3].num;
        let expireInMoreThanThirtyDays = this.dashboardForMeData[4].num;
        this.chartData = [
          // ['Total Sales Agreement', totalSa],
          ['Expire In 15 Days', expireInFifteenDays],
          ['Expire In More Than 30 Days', expireInMoreThanThirtyDays],
          ['Expire In 30 Days', expireInThirtyDays],
          ['Expire In 7 Days', expireInSevenDays]
        ];
      } else if (type == 'billing_status') {
        // let totalSa = this.dashboardBillingData[0].num;
        let timeSheetUnprocess = this.dashboardBillingData[1].num;
        let timeSheetProcess = this.dashboardBillingData[2].num;
        let invoiceGenerated = this.dashboardBillingData[3].num;
        this.chartData = [
          // ['Total Sales Agreement', totalSa],
          ['Time Sheet Process', timeSheetProcess],
          ['Time Sheet Unprocess', timeSheetUnprocess],
          ['Invoice Generated', invoiceGenerated]
        ];
      } else if (type == 'inventory') {
        // let totalArea = this.graphData.gtot_floor_area.replace(/,/g, '');
        this.unsoldArea = this.graphData.gunsold_area.replace(/,/g, '');
        // let soldArea = this.graphData.gsold_area.replace(/,/g, '');
        this.soldOutrightNotLeased = this.graphData.gsold_out_not_Leased_by_us.replace(
          /,/g,
          ''
        );
        this.soldOutrightLeased = this.graphData.gsold_out_Leased_by_us.replace(
          /,/g,
          ''
        );
        this.soldNonOutright = this.graphData.gsold_nonout.replace(/,/g, '');
        // let totalLeasableArea=this.graphData.gtotal_leaseable_area.replace(/,/g,'')
        // let leasedArea = this.graphData.gleased_area.replace(/,/g, '');
        //  let vacantArea = this.graphData.gvacant_area.replace(/,/g, '');
        this.leasedArea = Number(this.graphData.gleased_area.replace(/,/g, ''));
        this.vacantArea = Number(this.graphData.gvacant_area.replace(/,/g, ''));
        this.chartData = [
          // ['Total Floor Area', Number(totalArea)],
          ['Unsold Area', Number(this.unsoldArea)],
          // ['Sold Area', Number(soldArea)],
          [
            'Sold Outright Not Leased By Us',
            Number(this.soldOutrightNotLeased)
          ],
          ['Sold Outright Leased By Us', Number(this.soldOutrightLeased)],
          ['Sold Non Outright', Number(this.soldNonOutright)]

        
        ];
        this.chartData1 = [
          ['Leased Area ', Number(this.leasedArea)],
          ['Vacant Area ', Number(this.vacantArea)]
        ];
      }  else if (type == 'inventory') {
        // let totalArea = this.graphData.gtot_floor_area.replace(/,/g, '');
        let unsoldArea = this.graphData.gunsold_area.replace(/,/g, '');
        // let soldArea = this.graphData.gsold_area.replace(/,/g, '');
        let soldOutrightNotLeased = this.graphData.gsold_out_not_Leased_by_us.replace(/,/g,'');
        let soldOutrightLeased = this.graphData.gsold_out_Leased_by_us.replace(/,/g,'');
        let soldNonOutright = this.graphData.gsold_nonout.replace(/,/g, '');
        // let totalLeasableArea=this.graphData.gtotal_leaseable_area.replace(/,/g,'')
        let leasedArea = this.graphData.gleased_area.replace(/,/g, '');
        let vacantArea = this.graphData.gvacant_area.replace(/,/g, '');

        this.chartData = [
          // ['Total Floor Area', Number(totalArea)],
          ['Unsold Area', Number(unsoldArea)],
          // ['Sold Area', Number(soldArea)],
          ['Sold Outright Not Leased By Us', Number(soldOutrightNotLeased)],
          ['Sold Outright Leased By Us', Number(soldOutrightLeased)],
          ['Sold Non Outright', Number(soldNonOutright)]
          // ['Total Leaseable Area',Number(totalLeasableArea)]
        ];
        this.chartData1 = [
          ['Leased Area', Number(leasedArea)],
          ['Vacant Area', Number(vacantArea)]
        ];
      }

      const dashboardStatus =
        type === 'global'
          ? 'Global Dashboard'
          : type === 'for_me'
          ? 'For Me Dashboard'
          : type === 'billing_status'
          ? 'Billing Status Dashboard'
          : type === 'inventory'
          ? 'Inventory Status '
          : null;
      this.showCharts(dashboardStatus, size);
    },
    // showCharts(dashboardStatus) {

    //   Highcharts.chart('chart-cont', {
    //     chart: {
    //       type: 'pie',
    //       height: 330,
    //       borderRadius: 18,
    //       backgroundColor: 'rgb(255,255,255)',
    //     },
    //     title: {
    //       text: dashboardStatus,
    //       style: {
    //         color: 'black',
    //         fontWeight: 'bold',
    //       },
    //     },
    //     plotOptions: {
    //       pie: {
    //         allowPointSelect: true,
    //         cursor: 'pointer',
    //         dataLabels: {
    //           enabled: true,
    //           color: 'black',
    //           format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
    //           style: {
    //             fontFamily: 'Open Sans',
    //             fontWeight: 'x-small',
    //             textOutline: 'none',
    //           },
    //           distance: 20,
    //         },
    //       },
    //     },
    //     tooltip: {
    //       style: {
    //         fontFamily: 'Open Sans',
    //       },
    //     },
    //     credits: {
    //       enabled: false,
    //     },
    //     exporting: {
    //       enabled: false,
    //     },
    //     series: [
    //       {
    //         name: 'Data',
    //         size: '70%',
    //         data: this.chartData,
    //         innerSize: '30%',
    //       },
    //     ],
    //   });
    // },
    showCharts(dashboardStatus, size) {
      Highcharts.chart('chart-cont', {
        chart: {
          type: 'pie',
          height: size ? size : 330,
          borderRadius: 18,
          backgroundColor: 'rgb(255,255,255)',
          margin: [30, 20, 60, 50]
        
        },
        title: {
          text: dashboardStatus,
          style: {
            color: 'black',
            fontWeight: 'bold',
            fontSize: '20px',

          }
        },
        series: [
          {
            name: 'Leasing Status',
            innerSize: '30%',
            data: this.chartData
          }
        ],
        tooltip: {
          style: {
            fontFamily: 'Open Sans'
          }
        },

        legend: {
          enabled: true,
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'bottom',
          // floating: true,
          x: -10,
          y: 20,
          backgroundColor: 'white',
          itemStyle: {
            color: '#000000',
            fontWeight: 'normal',
            fontSize: '12px',
            fontFamily: 'Open Sans'
          },
          labelFormatter: function() {
            return this.name + ': ' + this.y;
          }
        },
        style: {
          fontFamily: 'Open Sans'
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              color: 'black',
              format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
              style: {
                fontSize: '10px',
                fontWeight: 'normal',
                fontFamily: 'Verdana, sans-serif'
              }
            },
            showInLegend: true,
            center: ['50%', '50%'],
            size: '70%',
            style: {
              fontFamily: 'Open Sans',
              fontWeight: 'x-small',
              textOutline: 'none'
            }
          }
        }
      });
      Highcharts.chart('chart-cont1', {
        chart: {
          type: 'pie',
          height: size ? size : 330,
          borderRadius: 18,
          backgroundColor: 'rgb(255,255,255)',
          margin: [10, 20, 60, 50]
        },
        title: {
          text: 'Leasing Status',
          style: {
            color: 'black',
            fontWeight: 'bold'
          }
        },
        series: [
          {
            name: 'Leasing Status',
            innerSize: '30%',
            data: this.chartData1
          }
        ],

        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom',
          backgroundColor: 'white',
          itemStyle: {
            color: '#000000',
            fontWeight: 'normal',
            fontSize: '12px',
            fontFamily: 'Open Sans'
          },
          labelFormatter: function() {
            return this.name + ': ' + this.y;
          }
        },

        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              color: 'black',
              format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
              style: {
                fontSize: '10px',
                fontWeight: 'normal',
                fontFamily: 'Verdana, sans-serif'
              }
            },
            showInLegend: true,
            center: ['50%', '50%'],
            size: '60%',
            style: {
              fontWeight: 'x-small',
              textOutline: 'none'
            }
          }
        },
        credits: {
          enabled: false
        },

        tooltip: {
          style: {
            fontFamily: 'Open Sans'
          }
        }
      });
      Highcharts.chart('chart-cont1', {
        chart: {
          type: 'pie',
          height: size ? size : 330,
          borderRadius: 18,
          backgroundColor: 'rgb(255,255,255)'
        },
        title: {
          text: this.dashboardStatus1,
          style: {
            color: 'black',
            fontWeight: 'bold'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              color: 'black',
              format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
              style: {
                fontFamily: 'Open Sans',
                fontWeight: 'x-small',
                textOutline: 'none'
              },
              distance: 20
            }
          }
        },
        tooltip: {
          style: {
            fontFamily: 'Open Sans'
          }
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        series: [
          {
            name: 'Data',
            size: '70%',
            data: this.chartData1,
            innerSize: '30%'
          }
        ]
      });
    },	
getEventsList() {
      const payload = {
        event_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('workflow/searchNotification', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.eventsList = response.data.data;
            this.modifyEventsList = response.data.data.map(data => {
              return {
                text: data.event_name,
                value: data.event_id,
                default: data.default
              };
            });
            this.modifyEventsList.filter(elem => {
              if (elem.default) {
                this.selectedEventFun(elem);
              }
            });
            // this.selectedEventFun(this.modifyEventsList[0]);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedEventFun(event) {
      this.eventId = event.value;
      this.selectedEvent = event;
      const payload = {
        status: 'INPROCESS',
        eventId: event.value
      };
      const findEventObj = this.eventsList.find(
        data => data.event_id === event.value
      );
      this.wfHdrIdkeyName = findEventObj.record_column.toLowerCase();
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getNotificationDetailByStatus', payload)
        .then(response => {
          this.loader = false;
          this.notificationDetailsList = [];
          this.notificationFields = [];
          if (response.status === 200 && response.data.data.data.length > 0) {
            const columnsField = response.data.data.columns;
            columnsField.forEach(index => {
              this.notificationFields.push({
                key: index.name,
                label: index.display_name,
                class: index.display ? '' : 'd-none'
              });
            });
            this.currentNotificationDetailsList = response.data.data.data;
            this.notificationDetailsList = response.data.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTableColumName(columns) {
      return columns.map(data => {
        return {
          key: data.name,
          label: data.display_name,
          class: data.display ? '' : 'd-none'
        };
      });
    },
    filteredEventList() {
      if (undefined === this.searchEventName || this.searchEventName === '')
        return this.currentNotificationDetailsList;
      return this.currentNotificationDetailsList.filter(product => {
        let flag;
        for (let prop in product) {
          flag = false;
          flag = product[prop].toString().indexOf(this.searchEventName) > -1;
          if (flag) break;
        }
        return flag;
      });
    },
    rowSelected(item) {
      this.wf_inprocess_dtl_id = item.wf_inprocess_dtl_id;
      this.wfHeaderId = item[this.wfHdrIdkeyName];
      this.$store.dispatch('shared/setMenuId', item.wf_menu_id);
      if (this.wfHdrIdkeyName === 'lease_master_agreement_hdr_id') {
        this.getLeaseMasterAgrDetails(item[this.wfHdrIdkeyName]);
      } else if (this.wfHdrIdkeyName === 'lease_tenant_agreement_hdr_id') {
        this.getLeaseTenantAgrDetails(item[this.wfHdrIdkeyName]);
      } else if (this.wfHdrIdkeyName === 'sales_agreement_hdr_id') {
        this.getSalesAgreementDetailsById(item[this.wfHdrIdkeyName]);
      } else if (this.wfHdrIdkeyName === 'lease_investor_agreement_hdr_id') {
        this.getLeaseInvestorAgrDetails(item[this.wfHdrIdkeyName]);
      } else if (this.wfHdrIdkeyName === 'lease_tenant_schedule_hdr_id') {
        this.leaseTenantScheduleHdrId = item.lease_tenant_schedule_hdr_id;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'lease_investor_schedule_hdr_id') {
        this.leaseInvestorScheduleHdrId = item.lease_investor_schedule_hdr_id;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'ap_h2h_payment_batch_id') {
        this.bankBatchId = item.ap_h2h_payment_batch_id;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 's_nfa_hdr_id') {
        this.resHdrId = item.s_nfa_hdr_id;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'fms_mutation_hdr_id') {
        this.fmsMutationHdrId = item.mutation_hdr_id;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'lease_prj_unit_id') {
        this.leasePrjUnitId = item.lease_prj_unit_id;
        this.unitDisplayName = item.unit_display_name;
        this.unitName = item.unit_name;
        this.projectName = item.project_name;
        this.floorName = item.floor_name;
        this.approvalStatus = item.approval_status;
        this.version = item.version;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'party_req_id') {
        this.dashboardDetails = item;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'vendor_inv_id') {
        this.invoiceApprovalDetails = item;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'liq_hdr_id') {
        this.assetLiquidationDetails = item;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'remedy_batch_id') {
        this.assetRemedyDetails = item;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'requisition_header_id') {
        this.wfPurReqDetails = item;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'prov_hdr_id') {
        this.wfProvisionDetails = item;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'cf_hdr_id') {
        this.wfCarryForwardDetails = item;
        this.hideIntWorkflow(true);
      } else if (this.wfHdrIdkeyName === 'rfp_header_id') {
        this.getAllRfpData(item);
        setTimeout(() => {
          this.hideIntWorkflow(true);
        }, 200);
      } else {
        this.getWfDetail(item[this.wfHdrIdkeyName]);
      }
    },
    getWfDetail(nfaHdrId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getWfDetailsById', nfaHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.selectedGridData = response.data.data;
            this.leaseHdrDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLeaseMasterAgrDetails(leaseMasterHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getMasterAgrmentDetails', leaseMasterHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leaseHdrDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLeaseTenantAgrDetails(leaseHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getTenantLeaseDetails', leaseHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leaseHdrDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSalesAgreementDetailsById(hdrId) {
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/getSalesAgreementDetailsById', hdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.wfSalesAgreementDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        });
    },
    getLeaseInvestorAgrDetails(leaseHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseInvestorDetails', leaseHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.leaseHdrDetails = response.data.data;
            this.hideIntWorkflow(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideIntWorkflow(flag) {
      this.showAddIntWorkflow = flag;
      if (!flag) {
        this.selectedEventFun(this.selectedEvent);
      }
    },
    updateInvoiceDetails() {
      this.hideIntWorkflow(false);
    },
    updateAssetLiqDetails() {
      this.hideIntWorkflow(false);
    },
    updateRemedyDetails() {
      this.hideIntWorkflow(false);
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    getAccessRightsData() {
      let roleMenuMpngId = null;
      for (let resp in this.responsibilities) {
        for (let key in this.responsibilities[resp]) {
          if (key === 'menu') {
            this.responsibilities[resp][key].forEach(elem => {
              if (elem.menu_name === 'REQUESTS') {
                for (let menu of elem.sub_menu) {
                  if (menu.menu_action === 'searchSubmittedRequest')
                    roleMenuMpngId = menu.role_menu_mpng_id;
                }
              }
            });
          }
        }
      }
      this.$store.dispatch('shared/setRoleMenuMpngId', roleMenuMpngId);
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideTermsConditionModal(flag) {
      this.showTermsConditionModal = flag;
    },
    getAdmindDashboardData() {
      const payload = {
        userId: this.$store.state.auth.userId
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/getAdmindDashboardData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.dashboardData = response.data.data;
            this.dashboardData.dashboard_data.map(ele => {
              this.DataOptions.push({
                value: ele.dashboard_name,
                text: ele.dashboard_name
              });
              if (ele.dashboard_name == 'SALES_AGREEMENT') {
                this.salesAgreementGlobalAccessFlag = ele.is_global_access;
              } else if (ele.dashboard_name == 'INVENTORY_SUMMARY') {
                this.inventoryGlobalAccessFlag = ele.is_global_access;
              }
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changeStatus() {
      this.changeFlag = !this.changeFlag;
      if (this.changeFlag) {
        this.figure = 'CHART';
        this.getInventoryDashboardData();
      } else {
        this.figure = 'TABLE';
        this.getLeaseInventoryDataForChart();
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = 2;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_TOWER_VSET) {
        this.parent_value_set_id = this.selectedProject.value;
      } else {
        this.parent_value_set_id = null;
      }
      if (vsetCode == 'FMS_TOWER_VSET' && this.selectedProject.value == null) {
        this.$bvToast.toast('Please Select Project First', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else {
        this.showValueSetModal = true;
        this.setTimeout = setTimeout(() => {
          const vSetData = {
            valueSetName: vsetCode,
            multiFlag: null
          };
          this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedProject.text = item.value_code;
        this.selectedProject.value = item.proj_id;
        this.selectedTower.text = null;
        this.selectedTower.value = null;
        this.getInventoryDashboardData();
        this.getLeaseInventoryDataForChart();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_TOWER_VSET) {
        this.selectedTower.text = item.fms_tower_name;
        this.selectedTower.value = item.fms_tower_id;
        this.getInventoryDashboardData();
        this.getLeaseInventoryDataForChart();
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedProject.text = null;
        this.selectedProject.value = null;
        this.selectedTower.text = null;
        this.selectedTower.value = null;
        if (this.changeFlag) {
          this.getInventoryDashboardData();
        } else {
          this.getLeaseInventoryDataForChart();
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_TOWER_VSET) {
        this.selectedTower.text = null;
        this.selectedTower.value = null;
        if (this.changeFlag) {
          this.getInventoryDashboardData();
        } else {
          this.getLeaseInventoryDataForChart();
        }
      }
    },
    getInventoryDashboardData() {
      // const payload = {
      //   _limit: this.perPage,
      //   _page: this.currentPage - 1,
      //   prj_id: this.selectedProject.value,
      //   tower_id:this.selectedTower.value
      // };
      this.filterData = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        prj_id: this.selectedProject.value,
        tower_id: this.selectedTower.value
      };
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/getLeaseInventoryData', this.filterData)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            // let result = response.data.data;
            this.inventoryData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLeaseInventoryDataForChart() {
      const payload = {
        prj_id: this.selectedProject.value,
        tower_id: this.selectedTower.value
      };
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/getLeaseInventoryDataForChart', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.graphData = response.data.data;
            this.totalRows = response.data.data.total_elements;
            this.totalLines('inventory', 300);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadExcl() {
      this.loader = true;
      const downloadpayload = { ...this.filterData };
      // downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'leaseInvoice/getLeaseInventoryData',
        'inventoryDashboard',
        () => (this.loader = false)
      );
    },
    callFunc() {
      if (this.selected == 'WORKLISTS') {
        this.oldDash();
      } else if (this.selected == 'SALES_AGREEMENT') {
        this.oldDashboardFunction();
      } else if (this.selected == 'INVENTORY_SUMMARY') {
        this.inventoryDashboardFunction();
      }
    },
    getAllRfpData(data) {
      const payload = {
        headerId: data.rfp_header_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.headerAllData = {
              approval_status: result.approval_status,
              description: result.description,
              end_date: result.end_date,
              le_id: result.le_id,
              le_name: result.le_name,
              rfp_header_id: result.rfp_header_id,
              rfp_number: result.rfp_number,
              rfp_status: result.rfp_status,
              rfp_type: result.rfp_type,
              start_date: result.start_date,
              user_department: result.user_department,
              version: result.version,
              rfp_basis: result.rfp_basis,
              user_id: data.user_id,
              budget: result.budget,
              retention: result.retention,
              payment_terms: result.payment_terms
            };
            this.itemAllData = result.rfp_item_dtls;
            (this.locationAllData = result.rfp_location_dtls),
              (this.negotiationAllData = result.rfp_negotiation_dtls),
              (this.vendorAllData = result.rfp_vendor_dtls),
              (this.getRfpModal = true);
            this.tncData = result.rfp_tc_dtls;
            this.boqAllData = result.rfp_boq_dtls;
            this.extendData = result.rfp_ext_dtls;
            if (this.extendData.length > 0) {
              this.rfpExtensionId = this.extendData[this.extendData.length - 1]
                .rfp_ext_id
                ? this.extendData[this.extendData.length - 1].rfp_ext_id
                : null;
            }
            this.rfpDataFromDashboard = {
              headerAllData: this.headerAllData,
              locationAllData: this.locationAllData,
              itemAllData: this.itemAllData,
              boqAllData: this.boqAllData,
              vendorAllData: this.vendorAllData,
              negotiationAllData: this.negotiationAllData,
              tncData: this.tncData,
              extendData: this.extendData,
              rfpHeaderId: data.rfp_header_id,
              userId: this.$store.state.auth.userId,
              menuIdForRfp: data.menu_id,
              wfDtlId: data.wf_inprocess_dtl_id
            };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAllRfp(headerId) {
      this.rfpHeaderId = headerId;
      this.getAllRfpData();
    }
  }
};
</script>

<style>
.modal-add-workflow-modal {
  max-width: initial;
  width: calc(100vw - 160px) !important;
}

.history-menu {
  list-style: none;
  margin: 0;
  padding: 0px;
  min-width: 9em;
  left: 100%;
  top: 0;
  margin-left: 1px;
}
.margin-li {
  margin-bottom: 2px;
}

.btn-vatika {
  width: 100%;
}

.dropbtn {
  background-color: #04aa6d;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.table-striped thead tr th div {
  width: 12.5vw !important;
}
li a {
  font-weight: 600 !important;
}
.workflowList {
  overflow: auto;
  height: calc(100vh - 56vh) !important;
  margin-bottom: 10px;
}

.chartConts {
  background-color: #343a40;
  border-radius: 20px;
  padding: 3px;
  margin-top: 6px;
}
.alignClass {
  text-align: right;
}
.alginLeft {
  text-align: left;
}
</style>
