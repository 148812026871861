import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'commonModalOracle',
  props: [
    'modalType',
    'vendorCode',
    'poNumber',
    'stateCode',
    'poLineNum',
    'legalEntityValue',
    'requestPara',
    'accountNum'
  ],
  components: {},
  watch: {
    currentPageLegalEntity: function() {
      this.getLegalEntityData();
    },
    perPageLegalEntity: function() {
      this.currentPageLegalEntity = 1;
      this.getLegalEntityData();
    },
    currentPagePeriod: function() {
      this.getPeriodMonth();
    },
    perPagePeriod: function() {
      this.currentPagePeriod = 1;
      this.getPeriodMonth();
    },
    currentPageVendor: function() {
      this.getVendorData();
    },
    perPageVendor: function() {
      this.currentPageVendor = 1;
      this.getVendorData();
    },
    currentPagePoNumber: function() {
      this.getPoNumberDetails();
    },
    perPagePoNumber: function() {
      this.currentPagePoNumber = 1;
      this.getPoNumberDetails();
    },
    // currentPageAccCode: function() {
    //   this.getAccCodeDetails();
    // },
    // perPageAccCode: function() {
    //   this.currentPageAccCode = 1;
    //   this.getAccCodeDetails();
    // },
    perPageStateCode: function() {
      this.currentPageStateCode = 1;
      this.getStateCodeDetails();
    },
    currentPageStateCode: function() {
      this.getStateCodeDetails();
    },
    perPageCostCenter: function() {
      this.currentPageCostCenter = 1;
      this.getCostCenterDetails();
    },
    currentPageCostCenter: function() {
      this.getCostCenterDetails();
    },
    currentPageAccNo: function() {
      this.getAccNoDetails();
    },
    perPageAccNo: function() {
      this.currentPageAccNo = 1;
      this.getAccNoDetails();
    },
    currentPageSubAccNo: function() {
      this.getSubAccNoDetails();
    },
    perPageSubAccNo: function() {
      this.currentPageSubAccNo = 1;
      this.getSubAccNoDetails();
    },
    currentPageProvisionNo: function() {
      this.getProvisionNoDetails();
    },
    perPageProvisionNo: function() {
      this.currentPageProvisionNo = 1;
      this.getProvisionNoDetails();
    },
    currentPageLob: function() {
      this.getLobDetails();
    },
    perPageLob: function() {
      this.currentPageLob = 1;
      this.getLobDetails();
    },
    currentPageCust: function() {
      this.getCustomersDataDtls();
    },
    perPageCust: function() {
      this.currentPageCust = 1;
      this.getCustomersDataDtls();
    },
    currentPageTax: function() {
      this.getTaxCategoryDataDtls();
    },
    perPageTax: function() {
      this.currentPageTax = 1;
      this.getTaxCategoryDataDtls();
    },
    currentPageTrxType: function() {
      this.getTrxTypeDataDtls();
    },
    perPageTrxType: function() {
      this.currentPageTrxType = 1;
      this.getTrxTypeDataDtls();
    },
    // Functions for PO Number automatic fill------------------->
    // selectAllCheckBox: function() {
    //   this.checkUncheckAllSelectBox();
    // }
  },
  data() {
    return {
      // Common Variables
      loader: false,
      // Legal Entity Form Variables
      perPageLegalEntity: commonHelper.perPageRecord,
      pageOptionsLegalEntity: commonHelper.getPageOption(),
      totalRowsLegalEntity: null,
      currentPageLegalEntity: 1,
      legalEntityData: [],
      legalEntityFields: [
        {
          key: 'le_id',
          label: 'Legal Entity ID'
        },
        {
          key: 'le_name',
          label: 'Legal Entity Name'
        }
      ],
      legalEntitySearch: null,
      // Period Form Variables
      perPagePeriod: commonHelper.perPageRecord,
      pageOptionsPeriod: commonHelper.getPageOption(),
      totalRowsPeriod: null,
      currentPagePeriod: 1,
      periodData: [],
      periodFields: [
        {
          key: 'period_name'
        }
      ],
      periodSearch: null,
      // Vendor Form Variables
      perPageVendor: commonHelper.perPageRecord,
      pageOptionsVendor: commonHelper.getPageOption(),
      totalRowsVendor: null,
      currentPageVendor: 1,
      vendorData: [],
      vendorFields: [
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_code'
        },
        {
          key: 'pan_number',
          label: 'PAN No.'
        }
      ],
      vendorNameSearch: '',
      vendorCodeSearch: '',
      vendorPanSearch: '',
      // PO Number Form Variables
      perPagePoNumber: commonHelper.perPageRecord,
      pageOptionsPoNumber: commonHelper.getPageOption(),
      totalRowsPoNumber: null,
      currentPagePoNumber: 1,
      poNumberData: [],
      poNumberFields: [
        // Functions for PO Number automatic fill------------------->
        // {
        //   key: 'selectBox',
        //   label: 'Select',
        //   variant: 'primary',
        //   stickyColumn: true
        // },
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'po_currency',
          label: 'PO Currency'
        },
        {
          key: 'line_num',
          label: 'PO Line Number'
        },
        {
          key: 'po_release_id',
          label: 'PO Release Id'
        },
        {
          key: 'item_description'
        },
        {
          key: 'CONCATENATED_SEGMENTS',
          label: 'Concatenated Segments'
        },
        {
          key: 'amount'
        }
      ],
      // Functions for PO Number automatic fill------------------->
      // selectAllCheckBox: false,
      poNumberSearch: '',
      // Account Code Form Variables
      perPageAccCode: commonHelper.perPageRecord,
      pageOptionsAccCode: commonHelper.getPageOption(),
      totalRowsAccCode: null,
      currentPageAccCode: 1,
      accCodeData: [],
      accCodeFields: [
        {
          key: 'concatenated_segments',
          label: 'Account Code'
        }
      ],
      accCodeSearch: '',
      // State Code Form Variables
      stateCodeData: [],
      stateCodeFields: [
        {
          key: 'state_code'
        }
      ],
      stateCodeSearch: '',
      perPageStateCode: commonHelper.perPageRecord,
      pageOptionsStateCode: commonHelper.getPageOption(),
      totalRowsStateCode: null,
      currentPageStateCode: 1,
      // Cost Center Form Variables
      costCenterData: [],
      costCenterFields: [
        {
          key: 'cost_center'
        },
        {
          key: 'status'
        },
        {
          key: 'deboarding_date'
        }
      ],
      costCenterSearch: '',
      perPageCostCenter: commonHelper.perPageRecord,
      pageOptionsCostCenter: commonHelper.getPageOption(),
      totalRowsCostCenter: null,
      currentPageCostCenter: 1,
      // Account No Form Variables
      perPageAccNo: commonHelper.perPageRecord,
      pageOptionsAccNo: commonHelper.getPageOption(),
      totalRowsAccNo: null,
      currentPageAccNo: 1,
      accNoData: [],
      accNoFields: [
        {
          key: 'account_num',
          label: 'Account No.'
        },
        {
          key: 'account_desc',
          label: 'Account Description'
        }
      ],
      accNoSearch: '',
      // Sub Account No Form Variables
      perPageSubAccNo: commonHelper.perPageRecord,
      pageOptionsSubAccNo: commonHelper.getPageOption(),
      totalRowsSubAccNo: null,
      currentPageSubAccNo: 1,
      subAccNoData: [],
      subAccNoFields: [
        {
          key: 'sub_account_num'
        },
        {
          key: 'description'
        }
      ],
      subAccNoSearch: '',
      // Provision Account No Form Variables
      perPageProvisionNo: commonHelper.perPageRecord,
      pageOptionsProvisionNo: commonHelper.getPageOption(),
      totalRowsProvisionNo: null,
      currentPageProvisionNo: 1,
      provisionNoData: [],
      provisionNoFields: [
        {
          key: 'prov_acc_num',
          label: 'Provision Account'
        },
        {
          key: 'account_desc',
          label: 'Account Description'
        }
      ],
      provisionNoSearch: '',
      // LOB Form Variables
      perPageLob: commonHelper.perPageRecord,
      pageOptionsLob: commonHelper.getPageOption(),
      totalRowsLob: null,
      currentPageLob: 1,
      lobData: [],
      lobFields: [
        {
          key: 'lob',
          label: 'LOB'
        }
      ],
      lobSearch: '',
      perPageCust: commonHelper.perPageRecord,
      pageOptionsCust: commonHelper.getPageOption(),
      totalRowsCust: null,
      currentPageCust: 1,
      customerSearch: null,
      customerData: [],
      customerFields: [
        {
          key: 'account_number',
          label: 'Account No.'
        },
        {
          key: 'customer_name',
          label: 'Customer Name'
        }
      ],
      customerSiteFields: [
        {
          key: 'postal_code',
          label: 'Postal Code'
        },
        {
          key: 'Customer_site_name',
          label: 'Customer Site Name'
        },
        {
          key: 'gst',
          label: 'GST'
        },
        {
          key: 'state',
          label: 'State'
        },
        {
          key: 'state',
          label: 'State'
        },
        {
          key: 'address',
          label: 'Address'
        }
      ],
      perPageTax: commonHelper.perPageRecord,
      pageOptionsTax: commonHelper.getPageOption(),
      totalRowsTax: null,
      currentPageTax: 1,
      taxCatSearch: null,
      taxCatData: [],
      taxCatFields: [
        // {
        //   key: 'ou_name',
        //   label: 'OU Name'
        // },
        {
          key: 'tax_category_name',
          label: 'Tax Category Name'
        }
      ],
      perPageTrxType: commonHelper.perPageRecord,
      pageOptionsTrxType: commonHelper.getPageOption(),
      totalRowsTrxType: null,
      currentPageTrxType: 1,
      trxTypeSearch: null,
      trxTypeData: [],
      trxTypeFields: [
        {
          key: 'name',
          label: 'TRX Type Name'
        },
        {
          key: 'type',
          label: 'TRX Type Code'
        }
      ]
    };
  },
  // computed: {
  //   filteredLegalEntityData() {
  //     if (!this.legalEntitySearch) {
  //       return this.legalEntityData;
  //     }
  //     const searchQuery = this.legalEntitySearch.toLowerCase();
  //     return this.legalEntityData.filter(item =>
  //       item.le_name.toLowerCase().includes(searchQuery)
  //     );
  //   }
  // },
  mounted() {
    // Functions for PO Number automatic fill------------------->
    // if(this.$route.name !== 'uploadProvisions'){
    //   this.poNumberFields.shift();
    // }
    if (this.modalType === 'legal_entity') {
      this.getLegalEntityData();
    } else if (
      this.modalType === 'period' ||
      this.modalType === 'expenseMonth' ||
      this.modalType === 'periodTo'
    ) {
      this.getPeriodMonth();
    } else if (this.modalType === 'po_number') {
      this.getPoNumberDetails();
    } else if (this.modalType === 'account_code') {
      this.getAccCodeDetails();
    }
    // else if (this.modalType === 'vendor') {
    //   this.getVendorData();
    // }
    else if (this.modalType === 'state_code') {
      this.getStateCodeDetails();
    }
    // else if (this.modalType === 'cost_center') {
    //   this.getCostCenterDetails();
    // }
    // else if (this.modalType === 'account_num') {
    //   this.getAccNoDetails();
    // }
    // else if (this.modalType === 'sub_account_num') {
    //   this.getSubAccNoDetails();
    // }
    else if (this.modalType === 'provision_account') {
      this.getProvisionNoDetails();
    } else if (this.modalType === 'lob') {
      this.getLobDetails();
    } else if (this.modalType === 'customer') {
      this.customerFields = this.customerFields;
      // this.getCustomersDataDtls();
    } else if (this.modalType === 'customer_site') {
      this.customerFields = this.customerSiteFields;
      this.getCustomersDataDtls();
    } else if (this.modalType === 'tax_category') {
      this.getTaxCategoryDataDtls();
    } else if (this.modalType === 'trx_type') {
      this.getTrxTypeDataDtls();
    }
  },
  methods: {
    // Functions for Legal Entity Form
    getLegalEntityData() {
      const payload = {
        _page: this.currentPageLegalEntity - 1,
        _limit: this.perPageLegalEntity,
        le_name: this.legalEntitySearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getlegalEntityListProvision', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.legalEntityData = result;
            this.totalRowsLegalEntity = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedLegalEntity(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearLegalEntity() {
      this.legalEntitySearch = null;
      this.getLegalEntityData();
    },
    // Functions for Period Form
    getPeriodMonth() {
      const payload = {
        _page: this.currentPagePeriod - 1,
        _limit: this.perPagePeriod,
        period_name: this.periodSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getExpenseMonth', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.periodData = result;
            this.totalRowsPeriod = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedPeriod(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearPeriod() {
      this.periodSearch = null;
      this.getPeriodMonth();
    },
    // Functions for Vendor Form
    getVendorData() {
      const payload = {
        _page: this.currentPageVendor - 1,
        _limit: this.perPageVendor,
        vendor_name: this.vendorNameSearch,
        vendor_code: this.vendorCodeSearch,
        pan_number: this.vendorPanSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getVendorData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.vendorData = result;
            this.totalRowsVendor = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedVendor(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearVendor() {
      this.vendorNameSearch = '';
      this.vendorCodeSearch = '';
      this.vendorPanSearch = '';
      this.getVendorData();
    },
    // Functions for PO Number Form
    getPoNumberDetails() {
      const payload = {
        _page: this.currentPagePoNumber - 1,
        _limit: this.perPagePoNumber,
        vendor_id: +this.vendorCode ? +this.vendorCode : "",
        po_number: this.poNumberSearch,
        le_id: this.legalEntityValue
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getPoNumberDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.poNumberData = result;
            this.totalRowsPoNumber = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedPoNumber(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearPoNumber() {
      this.poNumberSearch = '';
      this.getPoNumberDetails();
    },
    // Functions for PO Number automatic fill------------------->
    // checkUncheckAllSelectBox() {
    //   this.poNumberData = this.poNumberData.map(data => {
    //     data.selectBox = this.selectAllCheckBox;
    //     return data;
    //   });
    // },
    // selectedPoNumData() {
    //   const selectedPoNumber = this.poNumberData.filter(item => {
    //     if (item.selectBox) {
    //       return item;
    //     }
    //   });
    //   if (selectedPoNumber.length) {
    //     this.$emit('selectedPoNum', selectedPoNumber);
    //   } else {
    //     alert('Please Select Po Number');
    //   }
    // },

    // Functions for Account Code Form
    getAccCodeDetails() {
      const payload = {
        _page: this.currentPagePoNumber - 1,
        _limit: this.perPagePoNumber,
        po_num: this.poNumber,
        po_line_num: this.poLineNum,
        concatseg: '',
        account_code: this.accCodeSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getAccCodeDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.accCodeData = result;
            this.totalRowsAccCode = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedAccCode(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearAccCode() {
      this.accCodeSearch = '';
      this.getAccCodeDetails();
    },

    // Functions for State Code Form
    getStateCodeDetails() {
      const payload = {
        _page: this.currentPageStateCode - 1,
        _limit: this.perPageStateCode,
        state_code: this.stateCodeSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getStateCodeDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.stateCodeData = result;
            this.totalRowsStateCode = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearStateCode() {
      this.stateCodeSearch = '';
      this.getStateCodeDetails();
    },
    rowSelectedStateCode(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    // Functions for Cost Center Form
    getCostCenterDetails() {
      const payload = {
        _page: this.currentPageCostCenter - 1,
        _limit: this.perPageCostCenter,
        cost_center: this.costCenterSearch,
        state_code: this.stateCode ? this.stateCode : '',
        le_id: this.legalEntityValue
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getCostCenterDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.costCenterData = result;
            this.totalRowsCostCenter = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearCostCenter() {
      this.costCenterSearch = '';
      this.getCostCenterDetails();
    },
    rowSelectedCostCenter(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    // Functions for Account Code Form
    getAccNoDetails() {
      const payload = {
        _page: this.currentPageAccNo - 1,
        _limit: this.perPageAccNo,
        account_num: this.accNoSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getAccNoDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.accNoData = result;
            this.totalRowsAccNo = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedAccNo(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearAccNo() {
      this.accNoSearch = '';
      this.getAccNoDetails();
    },
    // Functions for Sub Account No Form
    getSubAccNoDetails() {
      const payload = {
        _page: this.currentPageSubAccNo - 1,
        _limit: this.perPageSubAccNo,
        account_num: this.accountNum,
        sub_account_num: this.subAccNoSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getSubAccNoDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.subAccNoData = result;
            this.totalRowsSubAccNo = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedSubAccNo(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearSubAccNo() {
      this.subAccNoSearch = '';
      this.getSubAccNoDetails();
    },
    // Functions for Provision Account No Form
    getProvisionNoDetails() {
      const payload = {
        _page: this.currentPageProvisionNo - 1,
        _limit: this.perPageProvisionNo,
        prov_acc_num: this.provisionNoSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getProvisionNoDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.provisionNoData = result;
            this.totalRowsProvisionNo = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedProvisionNo(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearProvisionNo() {
      this.provisionNoSearch = '';
      this.getProvisionNoDetails();
    },
    // Functions for LOB Form
    getLobDetails() {
      const payload = {
        _page: this.currentPageLob - 1,
        _limit: this.perPageLob,
        lob: this.lobSearch
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getLobDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.lobData = result;
            this.totalRowsLob = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedLob(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearLob() {
      this.lobSearch = '';
      this.getLobDetails();
    },
    // Functions for Customer and Customer Site Form
    getCustomersDataDtls() {
      this.customerData = [];
      const payload = {
        page: this.currentPageCust - 1,
        limit: this.perPageCust,
        type: this.requestPara.reqType,
        customer_name:
          this.modalType === 'customer' ? this.customerSearch : null,
        customer_site_name:
          this.modalType === 'customer_site' ? this.customerSearch : null,
        customer_id:
          this.modalType === 'customer_site'
            ? this.requestPara.customerId
            : null,
        ou_id:
          this.modalType === 'customer_site' ? this.requestPara.ou_id : null
      };
      this.loader = true;
      this.$store
        .dispatch('fcBilling/getCustomersData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            const result = resp.data.data.page;
            this.customerData = result;
            this.totalRowsCust = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedCust(item) {
      this.$emit('mapSelectedVsetData', item);
    },
    clearCust() {
      this.customerSearch = null;
      this.getCustomersDataDtls();
    },
    // Functions for tax category Form
    getTaxCategoryDataDtls() {
      const payload = {
        page: this.currentPageTax - 1,
        limit: this.perPageTax,
        ou_id: this.requestPara.ou_id,
        tax_category_name: this.taxCatSearch
      };
      this.loader = true;
      this.$store
        .dispatch('fcBilling/getTaxCategoryData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            const result = resp.data.data.page;
            this.taxCatData = result;
            this.totalRowsTax = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedTax(item) {
      item.vSetType = this.requestPara.vSetType;
      this.$emit('mapSelectedVsetData', item);
    },
    clearTax() {
      this.taxCatSearch = null;
      this.getTaxCategoryDataDtls();
    },
    // Functions for TRX Type Form
    getTrxTypeDataDtls() {
      const payload = {
        page: this.currentPageTrxType - 1,
        limit: this.perPageTrxType,
        ou_id: this.requestPara.ou_id,
        trx_type: this.trxTypeSearch
      };
      this.loader = true;
      this.$store
        .dispatch('fcBilling/getTrxTypeData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            const result = resp.data.data.page;
            this.trxTypeData = result;
            this.totalRowsTrxType = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedTrxType(item) {
      item.vSetType = this.requestPara.vSetType;
      this.$emit('mapSelectedVsetData', item);
    },
    clearTrxType() {
      this.taxCatSearch = null;
      this.getTaxCategoryDataDtls();
    },
    // Common Functions
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntitySearch) {
        this.legalEntitySearch = null;
      } else if (vsetCode === this.periodSearch) {
        this.periodSearch = null;
      } else if (vsetCode === this.vendorNameSearch) {
        this.vendorNameSearch = null;
      } else if (vsetCode === this.vendorCodeSearch) {
        this.vendorCodeSearch = null;
      } else if (vsetCode === this.poNumberSearch) {
        this.poNumberSearch = '';
      } else if (vsetCode === this.accCodeSearch) {
        this.accCodeSearch = null;
      } else if (vsetCode === this.stateCodeSearch) {
        this.stateCodeSearch = null;
      } else if (vsetCode === this.costCenterSearch) {
        this.costCenterSearch = null;
      } else if (vsetCode === this.accNoSearch) {
        this.accNoSearch = null;
      } else if (vsetCode === this.subAccNoSearch) {
        this.subAccNoSearch = null;
      } else if (vsetCode === this.provisionNoSearch) {
        this.provisionNoSearch = null;
      } else if (vsetCode === this.lobSearch) {
        this.lobSearch = null;
      } else if (vsetCode === this.customerSearch) {
        this.customerSearch = null;
      } else if (vsetCode === this.taxCatSearch) {
        this.taxCatSearch = null;
      } else if (vsetCode === this.trxTypeSearch) {
        this.trxTypeSearch = null;
      }
    }
  }
};
